import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Link } from "gatsby-theme-material-ui"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import LocationOnIcon from "@material-ui/icons/LocationOn"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import { isBoomBoomPage } from "../utils/helpers"

const LocationsPage = ({ data, location }) => {
  const classes = useStyles()
  let company = ""
  let index = 0

  if (isBoomBoomPage(location.pathname)) {
    company = "/boomboomchicken"
    index = 1
  }

  const { nodes } = data.allContentfulNavbar
  const { edges } = data.allContentfulCompanyInfo

  return (
    <Layout>
      <SEO
        title={edges[index].node.name}
        url={`${edges[index].node.url}/locations`}
        defaultUrl={edges[index].node.url}
        keywords={edges[index].node.keywords}
        seoDescription={edges[index].node.seoDescription}
      />

      <Navbar
        logo={nodes[index]}
        company={company}
        location={location}
        phone={edges[index].node.phone}
      />
      <div className={classes.locationsWrapper}>
        <Typography variant="h5">
          <span className={classes.locationsTitle}>
            Click to a location near you!
          </span>
        </Typography>
        <div className={classes.locationsContainer}>
          <div className={classes.location}>
            <Link color="textPrimary" to="/" className={classes.link}>
              <Img fluid={nodes[0].logo.fluid} className={classes.image} />
              <Typography variant="h5">
                <span className={classes.text}>Boom Chicken</span>
              </Typography>
              <div className={classes.city}>
                <LocationOnIcon />
                <Typography variant="h6">Lancaster</Typography>
              </div>
            </Link>
            <div className={classes.address}>
              <Typography variant="body1">
                {edges[0].node.streetAddress}
                <br />
                {edges[0].node.cityStateZip}
              </Typography>
            </div>
          </div>

          <div className={classes.location}>
            <Link
              color="textPrimary"
              to="/boomboomchicken"
              className={classes.link}
            >
              <Img fluid={nodes[1].logo.fluid} className={classes.image} />
              <Typography variant="h5" className={classes.text}>
                Boom Boom Chicken
              </Typography>
              <div className={classes.city}>
                <LocationOnIcon />
                <Typography variant="h6">Palmdale</Typography>
              </div>
            </Link>
            <div className={classes.address}>
              <Typography variant="body1">
                {edges[1].node.streetAddress}
                <br />
                {edges[1].node.cityStateZip}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default LocationsPage

LocationsPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

const useStyles = makeStyles(theme => ({
  locationsWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 20px 80px 20px",
    textAlign: "center",
    "@media (max-width: 620px)": {
      padding: "20px 20px 20px 20px",
    },
  },
  locationsTitle: {
    textTransform: "uppercase",
    fontSize: " 1.5rem",
    borderBottom: "4px solid red",
    "@media (max-width: 620px)": {
      borderBottom: "2px solid none",
    },
  },
  locationsContainer: {
    display: "flex",
    paddingTop: "60px",
    justifyContent: "space-around",

    "@media (max-width: 620px)": {
      paddingTop: "40px",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  location: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingBottom: "40px",
  },
  image: {
    width: "240px",
    margin: "auto",
  },
  link: {
    "&:focus, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
    "&:hover $text": {
      borderBottom: "4px solid red",
    },
    "&:hover $image": {
      filter: "brightness(87.5%)",
    },
  },
  text: {
    fontWeight: 450,
    color: "black",
    cursor: "pointer",
    alignItem: "center",
    borderBottom: "4px solid transparent",
    "&:focus, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
  },
  city: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "6px",
  },
}))

export const indexQuery = graphql`
  {
    allContentfulNavbar(sort: { fields: name, order: DESC }) {
      nodes {
        id
        name
        logo {
          fluid {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }

    allContentfulCompanyInfo(sort: { fields: name, order: DESC }) {
      edges {
        node {
          name
          seoDescription
          streetAddress
          cityStateZip
          phone
          url
        }
      }
    }
  }
`
